import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSelectedPipe = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedPipe } = slice;
	return selectedPipe;
};

export const selectSelectedPipeChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedPipeChangesClone } = slice;
	return selectedPipeChangesClone;
};
