/* eslint-disable import/no-cycle */
import { set, setCustomer, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { thunkLoad, thunkSetCustomerName } from './thunks';

export {
  set,
  setCustomer,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  
  thunkLoad,
  thunkSetCustomerName
};
