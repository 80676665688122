/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { setSelectedArea, setSelectedAreaChangesClone } = createActions(
	{
		SET_SELECTED_AREA: selectedArea => ({
			selectedArea
		}),
		SET_SELECTED_AREA_CHANGES_CLONE: selectedAreaChangesClone => ({
			selectedAreaChangesClone
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSelectedArea, setSelectedAreaChangesClone };
