import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSelectedArea = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedArea } = slice;
	return selectedArea;
};

export const selectSelectedAreaChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedAreaChangesClone } = slice;
	return selectedAreaChangesClone;
};
