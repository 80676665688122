/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from '../../api.util';
import { hasAccessToken } from '../../../../../scanline/utils/token';

export const listPsqlCustomerAreas = () => {
	const url = '/rel/customers/areas';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
