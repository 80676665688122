import {
	FETCH_LINES_START,
	FETCH_LINES_SUCCESS,
	FETCH_LINES_FAIL,
	SET_CURRENT_LINES_PAGE,
	LINES_SET_FILTERS,
	LINES_SET_CUSTOMER,
	CLEAR_CACHED_LINES_DATA,
	REQUEST_ADD_TAG,
	RECEIVE_ADD_TAG,
	ADD_TAG,
	REQUEST_DELETE_TAG,
	RECEIVE_DELETE_TAG,
	DELETE_TAG,
	REQUEST_GET_TAGS,
	RECEIVE_GET_TAGS
} from './actionTypes';

import {
	getNoLinesMessage,
	addTagToLine,
	removeTagFromLine,
	replaceLineInArray,
	replaceLineInLinesByPage
} from './util/line';

import {
	addToTagRequestTracker,
	deleteFromTagRequestTracker
} from './util/tag';

import {
	convertLinesByPageToArray,
	getLastPage,
	getLargestPage
} from './util/lines.paging';

import { goodEnoughUUID } from '../../utils/uuid';
import { getCustomer } from '../../../util/user';

const initialState = {
	lines: [],
	linesByPage: {},
	lastPage: undefined, // contains last requestable page from server if loaded, otherwise undefined
	largestPage: undefined, // contains the current largest page which has been loaded from server (largest in terms of index, not items)
	filters: [],
  isFetchingLines: false,
  isNextPagesRequest: false,
	fetchLinesFailed: false,
	showLinesLoading: false,
	currentPage: 1,
	customer: getCustomer(),
	customerAndFilterToken: undefined, // used to track changes to customer prop & filters prop, we should ignore a request to get lines if customerAndFilterToken has changed since request was made. (ie if filter params changed)
	noLinesMessage: undefined,
	customerTags: [],
	isAddingTag: {},
	isDeletingTag: {}
};

const lines = (state = initialState, action) => {
	const { type, payload } = action;

	let line;
	let newLine;

	switch (type) {
		case FETCH_LINES_START:
			return {
				...state,
				isFetchingLines: true,
				showLinesLoading: state.lines.length === 0,
				fetchLinesFailed: false,
        noLinesMessage: undefined,
        isNextPagesRequest: payload.isNextPagesRequest
			};

		case FETCH_LINES_SUCCESS: {
			const linesByPage = {
				...state.linesByPage,
				...payload
			};
			const convertedLines = convertLinesByPageToArray(linesByPage);

			const fetchLinesSuccessState = {
				...state,
				isFetchingLines: false,
				showLinesLoading: false,
				linesByPage,
				lines: convertedLines,
				lastPage: getLastPage({ linesByPage }),
				largestPage: getLargestPage({ linesByPage })
			};

			return {
				...fetchLinesSuccessState,
				noLinesMessage: getNoLinesMessage(fetchLinesSuccessState)
			};
		}

		case FETCH_LINES_FAIL:
			return {
				...state,
				isFetchingLines: false,
				showLinesLoading: false,
				fetchLinesFailed: true
			};

		case SET_CURRENT_LINES_PAGE:
			return {
				...state,
				currentPage: payload
			};

		case LINES_SET_FILTERS:
			return {
				...state,
				filters: payload,
				customerAndFilterToken: goodEnoughUUID()
			};

		case LINES_SET_CUSTOMER:
			return {
				...state,
				customer: payload,
				customerAndFilterToken: goodEnoughUUID()
			};

		case CLEAR_CACHED_LINES_DATA:
			return {
				...state,
				...initialState,
				customer: state.customer,
				customerAndFilterToken: state.customerAndFilterToken,
				customerTags: state.customerTags,
				filters: state.filters
			};

		case ADD_TAG:
			line = state.lines.find(l => l.internalId === payload.lineId);
			newLine = addTagToLine(line, payload.tag);

			return {
				...state,
				linesByPage: replaceLineInLinesByPage(state.linesByPage, line, newLine),
				lines: replaceLineInArray(state.lines, line, newLine)
			};

		case DELETE_TAG:
			line = state.lines.find(l => l.internalId === payload.lineId);
			newLine = removeTagFromLine(line, payload.tag);

			return {
				...state,
				linesByPage: replaceLineInLinesByPage(state.linesByPage, line, newLine),
				lines: replaceLineInArray(state.lines, line, newLine)
			};

		case REQUEST_ADD_TAG:
			return {
				...state,
				isAddingTag: addToTagRequestTracker(
					state.isAddingTag,
					payload.lineId,
					payload.tag
				)
			};

		case REQUEST_DELETE_TAG:
			return {
				...state,
				isDeletingTag: addToTagRequestTracker(
					state.isDeletingTag,
					payload.lineId,
					payload.tag
				)
			};

		case RECEIVE_ADD_TAG:
			return {
				...state,
				isAddingTag: deleteFromTagRequestTracker(
					state.isAddingTag,
					payload.lineId,
					payload.tag
				)
			};

		case RECEIVE_DELETE_TAG:
			return {
				...state,
				isDeletingTag: deleteFromTagRequestTracker(
					state.isDeletingTag,
					payload.lineId,
					payload.tag
				)
			};

		case REQUEST_GET_TAGS:
			return {
				...state,
				customerTags: []
			};

		case RECEIVE_GET_TAGS:
			return {
				...state,
				customerTags: [...new Set((payload.tags || []).map(t => t.tag_name))]
			};

		default:
			return state;
	}
};

export default lines;
