/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setSelectedRegulatoryStatus,
	setSelectedRegulatoryStatusChangesClone
} = createActions(
	{
		SET_SELECTED_REGULATORY_STATUS: selectedRegulatoryStatus => ({
			selectedRegulatoryStatus
		}),
		SET_SELECTED_REGULATORY_STATUS_CHANGES_CLONE: selectedRegulatoryStatusChangesClone => ({
			selectedRegulatoryStatusChangesClone
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSelectedRegulatoryStatus, setSelectedRegulatoryStatusChangesClone };
