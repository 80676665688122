/* eslint-disable import/no-cycle */
import {
	setSelectedRegulatoryStatus,
	setSelectedRegulatoryStatusChangesClone
} from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[setSelectedRegulatoryStatus]: (
		state,
		{ payload: { selectedRegulatoryStatus } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedRegulatoryStatus }
		};
	},

	[setSelectedRegulatoryStatusChangesClone]: (
		state,
		{ payload: { selectedRegulatoryStatusChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedRegulatoryStatusChangesClone }
		};
	}
};

export default reducerParts;
