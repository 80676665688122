/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';
import { createTableItemAndKey } from './util/linesaggs';

export const fetchLinesAggs = async (customerName) => {
	const url = '/lines';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'POST',
      data: {
        customer: customerName
      }      
		});

		return fetchData().then(res => res.map(i => createTableItemAndKey(i)));
	}
	return Promise.reject(new Error('Missing Token'));
};

