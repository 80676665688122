/* eslint-disable import/no-cycle */
import { setSelectedArea, setSelectedAreaChangesClone } from './actions';
import { ROOT_SLICE } from './constants';
import { selectRootSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[setSelectedArea]: (state, { payload: { selectedArea } }) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedArea }
		};
	},

	[setSelectedAreaChangesClone]: (
		state,
		{ payload: { selectedAreaChangesClone } }
	) => {
		const rootSlice = selectRootSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedAreaChangesClone }
		};
	}
};

export default reducerParts;
