import { mapToClientSurveyProps } from './survey.server';

export const mapToClientJobProps = (serverJob, lineId) => {
	const jobId = serverJob.job;

	return {
		lineId,
    id: jobId,
    jobNumber: jobId,
		scanlineEnabled: serverJob.scanline,
		entity: serverJob.entity,
		area: serverJob.area_name,
		pipeID: serverJob.pipe_name,
		regulatoryStatus: serverJob.regulatory_status_name,
		corrosionTechnician: serverJob.ct,
		projectManager: serverJob.pm,
		surveys: (serverJob.surveys || []).map(survey =>
			mapToClientSurveyProps(survey, lineId, jobId)
		)
	};
};

export default { mapToClientJobProps };
