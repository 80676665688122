/* eslint-disable no-param-reassign */
import { createSortNumeric } from 'aegion_common_utilities/lib/Sort';
import { sortSurveysByType } from '../../../../utils/surveys.sort/base';

/**
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

export const createLineKey = (prefix, lineagg) => {
	return `${prefix}-${lineagg.customerId}-${lineagg.lineName}`;
};

const convertDateToEpoch = (dt) => { 
  if (!isDefined(dt)) { 
    return -1;
  }

  return new Date(dt).getTime();
}

const sortBySortEpoch = createSortNumeric('DESC', {
  invalidLast: true,
  getValue: (s) => s.sortEpoch
});

const flipIfLastNameIsFirst = name => {
  if (!name) { 
    return name;
  }
  const partsName = name.split(',').map(v => v.trim());
  partsName.reverse();
  return partsName.join(' ');
}



/**
 * HELPERS - SURVEYS
 */

const injectSurveysSortEpoch = surveys => {
  return (surveys || [])
    .map(s => ({
      ...s,
      sortEpoch: Math.max(convertDateToEpoch(s?.start), convertDateToEpoch(s?.end))
    }));
};


/**
 * HELPERS - JOB
 */

const injectJobSortEpochAndStartEnd = job => {
  if (!job) { 
    return job;
  }

  const res = {
    ...job,
    jobNumber: job.jobNumber || job.job
  };

  res.surveys = injectSurveysSortEpoch(job.surveys);
  res.surveys.sort(sortBySortEpoch);
  res.sortEpoch = isDefined(res.surveys[0]?.sortEpoch) ? res.surveys[0]?.sortEpoch : -1;
  res.start = isDefined(res.surveys[0]?.start) ? res.surveys[0]?.start : undefined;
  res.end = isDefined(res.surveys[0]?.end) ? res.surveys[0]?.end : undefined;
  return res;
};

const pushUniqueValue = (v, arr, refSet) => {   
  if (isDefined(v) && !refSet.has(v)) {
    refSet.add(v)
    arr.push(v);
  }
}

const liftJobProps = (line) => {
  const refJobNumbers = new Set([]);
  const arrJobNumbers = [];

  const refEntities = new Set([]);
  const arrEntities = [];

  const refAreas = new Set([]);
  const arrAreas = [];

  const refPipeIDs = new Set([]);
  const arrPipeIDs = [];

  const refRegStatuses = new Set([]);
  const arrRegStatuses = [];

  const refCTs = new Set([]);
  const arrCTs = [];

  const refPMs = new Set([]);
  const arrPMs = [];

  const refSurveyTypes = new Set([]);
  const arrSurveyTypes = [];

  (line?.jobs || []).forEach(j => {
    pushUniqueValue(j?.jobNumber, arrJobNumbers, refJobNumbers);
    pushUniqueValue(j?.entity, arrEntities, refEntities);
    pushUniqueValue(j?.area_name, arrAreas, refAreas);
    pushUniqueValue(j?.pipe_name, arrPipeIDs, refPipeIDs);
    pushUniqueValue(j?.regulatory_status_name, arrRegStatuses, refRegStatuses);
    pushUniqueValue(flipIfLastNameIsFirst(j?.ct), arrCTs, refCTs);
    pushUniqueValue(flipIfLastNameIsFirst(j?.pm), arrPMs, refPMs);
    (j?.surveys || []).forEach(s => {
      pushUniqueValue(s?.type, arrSurveyTypes, refSurveyTypes);
    });
  });

  line.jobNumbers = arrJobNumbers.join('~');
  line.entities = arrEntities.join('~');
  line.areas = arrAreas.join('~');
  line.pipeIDs = arrPipeIDs.join('~');
  line.regulatoryStatuses = arrRegStatuses.join('~');
  line.cts = arrCTs.join('~');
  line.pms = arrCTs.join('~');
  line.surveyTypes = sortSurveysByType(arrSurveyTypes).join('~');
}

/**
 * MAIN
 */

export const createTableItemAndKey = lineagg => {
  if (!lineagg) { 
    return lineagg;
  }
  
  const key = createLineKey('tbl-row', lineagg);
  const tableItem = {
    tblRowKey: key,
    ...lineagg
  };

  tableItem.jobs = (tableItem.jobs || []).map(j => injectJobSortEpochAndStartEnd(j));
  tableItem.jobs.sort(sortBySortEpoch);
  liftJobProps(tableItem);

  return tableItem;
}

export default {
  createTableItemAndKey
};