import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already lines aggs.
  return state;
};

export const selectItems = state => {
	const slice = selectThisSlice(state);
  const { items } = slice;
	return items;
};

export const selectItemsMap = state => {
	const slice = selectThisSlice(state);
	const { itemsMap } = slice;
	return itemsMap;
};

export const selectIsLoading = state => {
	const slice = selectThisSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectThisSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectCustomer = (state) => { 
	const slice = selectThisSlice(state);
	const { customer } = slice;
	return customer;
}

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export default {
	selectItems,
	selectItemsMap,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
