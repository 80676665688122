import { createSortNumeric } from 'aegion_common_utilities/src/Sort/sort';
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';
import { selectDataByCustomer } from '../Sync/PsqlCustomerAreas/selectors';

const isDefined = v => v !== null && v !== undefined;

const isEmptyString = v => typeof v === 'string' && v.trim().length === 0;

const CORE_PROPS = [
	'id',
	'customerId',
	'entityId',
	'entityClientId',
	'name',
	'hasValues'
];

const createAreaObject = (area = {}, entities) => {
	const entity = area.entityId ? entities.find(e => area.entityName && e.name === area.entityName) : undefined;
	const result = {
    ...area,
    clientId: goodEnoughUUID(),
		entityClientId: entity?.clientId,
  };

	return result;
};

const sortAreas = createSortNumeric('ASC', {
	getValue: i => i.name,
	caseSensitive: false,
	invalidLast: true
});

const createAreasArray = (stateAdmin, customerName, entities) => {
	const psqlAreas = selectDataByCustomer(stateAdmin, {
		customer: customerName
	});

	const _createAreaObject = area => createAreaObject(area, entities);
	return psqlAreas.map(_createAreaObject).sort(sortAreas);
};

const areaHasChanges = area => !area.id || !!area.hasChanges;

const areaIsNew = area => !area?.id;

const areaIsEmpty = area => {
	if (!area) {
		return true;
	}

	const CHECK_PROPS = ['name', 'entityId'];
	const hasDefinedProp = CHECK_PROPS.some(
		p => isDefined(area[p]) && !isEmptyString(area[p])
	);
	return !hasDefinedProp;
};

const areaRemoveEmptyStringProps = area => {
	if (!area) {
		return area;
	}

	let isModified = false;
	const clone = { ...area };
	CORE_PROPS.forEach(p => {
		if (isEmptyString(clone[p])) {
			clone[p] = undefined;
			isModified = true;
		}
	});

	return isModified ? clone : area;
};

const areaTrimPropWrappingWhiteSpace = area => {
	if (!area) {
		return area;
	}

	let isModified = false;
	const clone = { ...area };
	CORE_PROPS.forEach(p => {
		if (
			typeof clone[p] === 'string' &&
			clone[p].trim().length !== clone[p].length
		) {
			clone[p] = clone[p].trim();
			isModified = true;
		}
	});

	return isModified ? clone : area;
};

const theCloneAreaHasChanges = (orig = {}, clone = {}) => {
	const res = CORE_PROPS.some(p => clone[p] !== orig[p]);
	return res;
};

export {
	areaIsNew,
	areaIsEmpty,
	areaRemoveEmptyStringProps,
	areaTrimPropWrappingWhiteSpace,
	createAreaObject,
	createAreasArray,
	areaHasChanges,
	theCloneAreaHasChanges
};
