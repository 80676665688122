/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { selectIsLoaded, selectIsLoading } from '../selectors';
import { set, setIsLoadingLoaded, clear } from './actions';
import { listPsqlCustomerAreas } from './api';

const thunkLoad = () => dispatch => {
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
	listPsqlCustomerAreas().then(({ data = {} } = {}) => {
		dispatch(set({ data }));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
	const isLoaded = selectIsLoaded(getState());
	const isLoading = selectIsLoading(getState());
	if (!isLoaded && !isLoading) {
		dispatch(thunkLoad());
	}
};

export { thunkLoad, thunkLoadIfNotLoaded };
