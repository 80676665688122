/* eslint-disable no-use-before-define */
import { createPayloadMetaAction as cpma } from '../util';
import * as api from './util/api';

import {
	FETCH_LINES_START,
	FETCH_LINES_SUCCESS,
	SET_CURRENT_LINES_PAGE,
	LINES_SET_FILTERS,
	LINES_SET_CUSTOMER,
	CLEAR_CACHED_LINES_DATA,
	REQUEST_ADD_TAG,
	RECEIVE_ADD_TAG,
	ADD_TAG,
	REQUEST_DELETE_TAG,
	RECEIVE_DELETE_TAG,
	DELETE_TAG,
	REQUEST_GET_TAGS,
	RECEIVE_GET_TAGS
} from './actionTypes';

import { getPrevPage, getNextPage } from './util/lines.paging';
import scanlineUtils from '../../scanlineUtils';
import { getCustomer } from '../../../util/user';

export const fetchLines = ({
	filters: paramFilters,
	customer: paramCustomer,
	pageStart,
  pageEnd,
  isNextPagesRequest = false
}) => (dispatch, getState) => {
	const { lines: state } = getState();

  const { customerAndFilterToken: token, isFetchingLines, isNextPagesRequest: isPrevNextPagesRequest } = state;
  
  if (isNextPagesRequest && isFetchingLines && isPrevNextPagesRequest) { 
    // ignore, we are already requesting the next page
    return;
  }

	const filters = paramFilters || state.filters;
	const customer = paramCustomer || state.customer;
	const requestObject = api.createFetchLinesRequestObject({
		filters,
		customer,
		pageStart,
		pageEnd
	});
	const onFetchSuccess = (rawLines = []) => {
		const { customerAndFilterToken: confirmToken } = getState().lines;
		const ignoreResults = confirmToken !== token;

		if (ignoreResults) {
			dispatch(fetchLinesSuccess({}));
		} else {
			const lines = api.mapServerLinesToClientLines(rawLines);
			const pages = api.getPagesFromFetchLinesRequestObject(requestObject);
			const linesByPage = api.createLinesByPage(lines, pages);

			dispatch(fetchLinesSuccess(linesByPage));
		}
	};

  dispatch(fetchLinesStart({ isNextPagesRequest }));
	api.fetchLines(requestObject, onFetchSuccess);
};

export const setCurrentPage = page => (dispatch, getState) => {
	dispatch(acSetCurrentPage(page));

	const { lines } = getState();
	if (!lines.lastPage) {
		const nextPageToLoad = (lines.largestPage || 0) + 1;
		const pageDiff = nextPageToLoad - page;

		if (pageDiff > -6 && pageDiff < 6) {
			dispatch(fetchLines({ pageStart: nextPageToLoad, isNextPagesRequest: true }));
		}
	}
};

export const goNextPage = () => (dispatch, getState) => {
	const { lines: state } = getState();
	dispatch(setCurrentPage(getNextPage(state)));
};

export const goPrevPage = () => (dispatch, getState) => {
	const { lines: state } = getState();
	dispatch(setCurrentPage(getPrevPage(state)));
};

export const linesSetFilters = filters => dispatch => {
	dispatch(clearCachedLinesData());
	dispatch(acLinesSetFilters(filters));
	dispatch(fetchLines({ pageStart: 1, isNextPagesRequest: false }));
};

export const linesSetCustomer = customer => dispatch => {
	const cleanedCustomer = customer || getCustomer();

	dispatch(clearCachedLinesData());
	dispatch(acLinesSetCustomer(cleanedCustomer));
	dispatch(fetchLines({ pageStart: 1, isNextPagesRequest: false }));
};

export const addTag = ({ lineId, tag }) => dispatch => {
	dispatch(acRequestAddTag({ lineId, tag }));
	scanlineUtils.addLineTag(lineId, tag, err => {
		if (!err) {
			dispatch(acAddTag({ lineId, tag }));
			dispatch(fetchCustomerTags());
		}
		dispatch(acReceiveAddTag({ lineId, tag }));
	});
};

export const deleteTag = ({ lineId, tag }) => dispatch => {
	dispatch(acRequestDeleteTag({ lineId, tag }));
	scanlineUtils.deleteLineTag(lineId, tag, err => {
		if (!err) {
			dispatch(acDeleteTag({ lineId, tag }));
			dispatch(fetchCustomerTags());
		}
		dispatch(acReceiveDeleteTag({ lineId, tag }));
	});
};

export const fetchCustomerTags = () => dispatch => {
	dispatch(acRequestGetTags());
	scanlineUtils.getTags((err, res) => {
		if (!err) {
			dispatch(acReceiveGetTags({ tags: res }));
		} else {
			dispatch(acReceiveGetTags({ tags: [] }));
		}
	});
};

const fetchLinesStart = cpma(FETCH_LINES_START);
const fetchLinesSuccess = cpma(FETCH_LINES_SUCCESS);

const acSetCurrentPage = cpma(SET_CURRENT_LINES_PAGE);

const acLinesSetFilters = cpma(LINES_SET_FILTERS);
const acLinesSetCustomer = cpma(LINES_SET_CUSTOMER);
const clearCachedLinesData = cpma(CLEAR_CACHED_LINES_DATA);

const acRequestAddTag = cpma(REQUEST_ADD_TAG);
const acReceiveAddTag = cpma(RECEIVE_ADD_TAG);
const acAddTag = cpma(ADD_TAG);

const acRequestDeleteTag = cpma(REQUEST_DELETE_TAG);
const acReceiveDeleteTag = cpma(RECEIVE_DELETE_TAG);
const acDeleteTag = cpma(DELETE_TAG);

const acRequestGetTags = cpma(REQUEST_GET_TAGS);
const acReceiveGetTags = cpma(RECEIVE_GET_TAGS);
