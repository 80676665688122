/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	theClonePipeHasChanges,
	createPipeObject,
	pipeIsEmpty,
	pipeIsNew,
	pipeRemoveEmptyStringProps,
	pipeTrimPropWrappingWhiteSpace
} from '../util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
// import {
// 	selectSelectedPipe,
// 	selectSelectedPipeChangesClone
// } from '../selectors';
import { setSelectedPipe, setSelectedPipeChangesClone } from './actions';

import {
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone
} from '../../CustomersV2/selectors';
import { setSelectedCustomerChangesClone } from '../../CustomersV2/actions';



/** **********
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const createCustomerClone = customerObj => {
	return {
		...customerObj,
		pipes: customerObj.pipes ? [...customerObj.pipes] : []
	};
};

const computeHasNameConflict = (pipe, pipes) => {
  return !pipe.isDeleted && (pipes || [])
    .filter(p => p.clientId !== pipe.clientId)
    .some(p => (
      !p.isDeleted && (
        (p.name || '').trim().toUpperCase() === (pipe.name || '').trim().toUpperCase()
      )
    ));
}



/** **********
 * STATE - various clones ( customers & pipes )
 */

const selectProps = (getState, pipe) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const origCustomer = selectSelectedCustomer(stateAdmin);
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const newCustomerClone = createCustomerClone(customerChangesClone);

	const origData = {
		customer: origCustomer,
    pipes: origCustomer.pipes,
    idx: -1,
    pipe: undefined
	};

	const chngData = {
		customer: customerChangesClone,
		pipes: customerChangesClone.pipes,
    idx: -1,
    pipe: undefined
	};

	const newData = {
		customer: newCustomerClone,
		pipes: newCustomerClone.pipes,
    idx: -1,
    pipe: undefined
	};

	if (isDefined(pipe)) {
		origData.idx = (origData.pipes || []).findIndex(
			o => o.clientId === pipe.clientId
		);
		if (origData.idx > -1) {
			origData.pipe = origData.pipes[origData.idx];
		}
		chngData.idx = (chngData.pipes || []).findIndex(
			o => o.clientId === pipe.clientId
		);
		if (chngData.idx > -1) {
			chngData.pipe = chngData.pipes[chngData.idx];
		}
		newData.idx = (newData.pipes || []).findIndex(
			o => o.clientId === pipe.clientId
		);
		if (newData.idx > -1) {
			newData.pipes[newData.idx] = { ...newData.pipes[newData.idx] };
			newData.pipe = newData.pipes[newData.idx];
		}
		// debugger;
	} else {
		// debugger;
	}

	return {
		origData,
		chngData,
		newData
	};
};



/** **********
 * THUNKS
 */

const thunkSetSelectedPipe = pipe => dispatch => {
	if (isDefined(pipe)) {
		const clone = { ...pipe };
		dispatch(setSelectedPipe(pipe));
		dispatch(setSelectedPipeChangesClone(clone));
	} else {
		dispatch(setSelectedPipe(undefined));
		dispatch(setSelectedPipeChangesClone(undefined));
	}
};

const thunkSetNewSelectedPipe = () => (dispatch, getState) => {
	const { newData } = selectProps(getState);
	const newPipe = createPipeObject({
		customerId: newData.customer.id
	});
	newData.customer.pipes.push(newPipe);
	dispatch(setSelectedCustomerChangesClone(newData.customer));
	dispatch(thunkSetSelectedPipe(newPipe));
};

const thunkUpdatePipeProp = (pipe, prop, value) => (dispatch, getState) => {
	const { chngData } = selectProps(getState);
	const isPropChanged = pipe && pipe[prop] !== value;

	if (isPropChanged) {
		const clone = { ...pipe };
		clone[prop] = value;
		clone.hasChanges = true;
    clone.hasKeyConflict = computeHasNameConflict(clone, chngData.pipes);
		dispatch(setSelectedPipeChangesClone(clone));
	}
};

const thunkDeletePipe = pipe => (dispatch, getState) => {
	const { newData } = selectProps(getState, pipe);
	if (newData.pipe && !newData.pipe.isDeleted) {
		if (pipeIsNew(newData.pipe)) {
			newData.pipes.splice(newData.idx, 1);
		} else {
			newData.pipe.isDeleted = true;
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
	}
};

const thunkCancelPipeEditor = pipe => (dispatch, getState) => {
	const { newData } = selectProps(getState, pipe);
	const isNewAndEmpty =
		newData.pipe && pipeIsNew(newData.pipe) && pipeIsEmpty(newData.pipe);
	if (isNewAndEmpty) {
		dispatch(thunkDeletePipe(pipe));
	}
	dispatch(thunkSetSelectedPipe(undefined));
};

const thunkDoneEditingPipe = pipe => (dispatch, getState) => {
	if (pipe && !pipe.hasKeyConflict) {
		const { newData } = selectProps(getState, pipe);
		if (newData.idx > -1) {
			const newPipeIsEmpty = pipeIsNew(pipe) && pipeIsEmpty(pipe);
			const existingPipeIsEmpty = !pipeIsNew(pipe) && pipeIsEmpty(pipe);
			const existingPipeNoChanges =
				!pipeIsNew(pipe) &&
				!existingPipeIsEmpty &&
				!theClonePipeHasChanges(newData.pipe, pipe);

			// UPDATE STATE
			if (newPipeIsEmpty) {
				dispatch(thunkDeletePipe(pipe));
				dispatch(thunkSetSelectedPipe(undefined));
			} else if (existingPipeNoChanges) {
				dispatch(thunkSetSelectedPipe(undefined));
			} else if (existingPipeIsEmpty) {
				// do nothing, should be caught in form validation and never make it to this line of code.
			} else {
				const sanitizedPipe = pipeTrimPropWrappingWhiteSpace(
					pipeRemoveEmptyStringProps(pipe)
				);
				newData.pipe = sanitizedPipe;
				newData.pipes[newData.idx] = newData.pipe;
				dispatch(setSelectedCustomerChangesClone(newData.customer));
				dispatch(thunkSetSelectedPipe(undefined));
			}
		}
	}
};

const thunkRevertPipeChanges = pipe => (dispatch, getState) => {
	const { origData, newData } = selectProps(getState, pipe);

	if (newData.idx > -1) {
    if (origData.idx > -1) {
      const clone = { ...origData.pipes[origData.idx] };
      newData.pipes[newData.idx] = clone;
      clone.hasKeyConflict = computeHasNameConflict(clone, newData.pipes);
		} else {
			newData.pipes.splice(newData.idx, 1);
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
		dispatch(thunkSetSelectedPipe(undefined));
	}
};

export {
	thunkSetSelectedPipe,
	thunkSetNewSelectedPipe,
	thunkDoneEditingPipe,
	thunkUpdatePipeProp,
	thunkDeletePipe,
	thunkCancelPipeEditor,
	thunkRevertPipeChanges
};
