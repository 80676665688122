/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { setSelectedPipe, setSelectedPipeChangesClone } = createActions(
	{
		SET_SELECTED_PIPE: selectedPipe => ({
			selectedPipe
		}),
		SET_SELECTED_PIPE_CHANGES_CLONE: selectedPipeChangesClone => ({
			selectedPipeChangesClone
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSelectedPipe, setSelectedPipeChangesClone };
