/* eslint-disable import/no-named-as-default-member */
import _ from 'lodash';

import DDB_CUSTOMERS from '../../Sync/DdbCustomers/selectors';
import DDB_SETTINGS from '../../Sync/DdbCustomersSettings/selectors';

import { cleanName } from '../../../../api/util/customer';
import { createApplicationsMap } from '../../Applications/util';
import { createEntitiesArray } from '../../EntitiesEditor/util';
import { createAreasArray } from '../../AreasEditor/util';
import { createRegulatoryStatusesArray } from '../../RegulatoryStatusEditor/util';
import { createPipesArray } from '../../PipesEditor/util';

/** ************************************
 * SETTINGS
 */
const createSettingsMap = (stateAdmin, customerName) => {
	const settings = DDB_SETTINGS.selectDataByCustomer(stateAdmin, {
		customer: customerName
	});

	return _.cloneDeep(settings);
};

const overWriteAssetViewSettings = (
	selectedCustomerChangesClone,
	defaultMatchTerm,
	{ ignoreEnabled = false } = {}
) => {
	const obj = selectedCustomerChangesClone;
	const ASSETVIEW = 'ASSETVIEW®';
	const avAppkeys = ['ASSETVIEW', 'ASSETVIEW®'];
	avAppkeys.forEach(appKey => {
		obj.settings[appKey] = obj.settings[appKey] || {};
		obj.settings[appKey].companyName = cleanName(obj.name);
		if (!ignoreEnabled) {
			obj.settings[appKey].enabled = !!obj.applications[ASSETVIEW];
		}
		if (!obj.settings[appKey].elasticsearchKeyMatcher) {
			if (defaultMatchTerm) {
				obj.settings[appKey].elasticsearchKeyMatcher = defaultMatchTerm;
			} else {
				obj.settings[appKey].elasticsearchKeyMatcher = cleanName(obj.name);
			}
		}
	});
};

const enableAssetViewSettings = (selectedCustomerChangesClone, enable) => {
	const obj = selectedCustomerChangesClone;
	const avAppkeys = ['ASSETVIEW', 'ASSETVIEW®'];
	avAppkeys.forEach(appKey => {
		obj.settings[appKey].enabled = !!enable;
	});
};

/** ************************************
 * CUSTOMER
 */
/* eslint-disable import/prefer-default-export */
const createCustomerObject = (stateAdmin, customerName) => {
	const customer = {
		name: customerName,
		domain: DDB_CUSTOMERS.selectDomain(stateAdmin, { customer: customerName }),
		applications: createApplicationsMap(stateAdmin, customerName)
	};
	customer.entities = createEntitiesArray(stateAdmin, customerName);
	customer.areas = createAreasArray(
		stateAdmin,
		customerName,
		customer.entities
	);
	customer.regulatorystatuses = createRegulatoryStatusesArray(
		stateAdmin,
		customerName
  );
  customer.pipes = createPipesArray(stateAdmin, customerName, customer.entities, customer.areas);
	customer.settings = createSettingsMap(stateAdmin, customerName);

	return customer;
};

export {
	createCustomerObject,
	overWriteAssetViewSettings,
	enableAssetViewSettings
};
