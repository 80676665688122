import { compose, applyMiddleware, createStore, combineReducers } from 'redux';

// eslint-disable-next-line import/no-cycle
import { browserHistory, middleware, enhancers, reducers } from './util/parts';

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
	// , ...[
	// 	...enhancers,
	// 	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	// ]
);

const store = createStore(combineReducers(reducers), composedEnhancers);

export { store, browserHistory };
