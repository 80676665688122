/* eslint-disable import/no-named-as-default-member */
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';
import PSQL_APPLICATIONS from '../Sync/PsqlCustomersApplications/selectors';

export const createNewApplicationObject = () => ({
	clientId: goodEnoughUUID(),
	name: '',
	summary: '',
	learnmore: '',
	icon: '',
	path: '',
	order: 0,
	external: false
});

export const createApplicationsMap = (stateAdmin, customerName) => {
	const psqlAppMap = PSQL_APPLICATIONS.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});

	return { ...psqlAppMap };
};

export default { createNewApplicationObject, createApplicationsMap };
