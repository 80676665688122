/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setIsLoadingLoaded, clear } from './actions';
import { listPsqlAreas } from '../../../../../api/areas';

const thunkLoad = () => dispatch => {
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
	listPsqlAreas().then(({ data = {} } = {}) => {
		dispatch(set({ data }));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

export { thunkLoad };
