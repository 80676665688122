import { ROOT_SLICE } from './constants';

export const selectRootSlice = stateAdmin => stateAdmin[ROOT_SLICE];

export const selectSelectedRegulatoryStatus = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedRegulatoryStatus } = slice;
	return selectedRegulatoryStatus;
};

export const selectSelectedRegulatoryStatusChangesClone = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { selectedRegulatoryStatusChangesClone } = slice;
	return selectedRegulatoryStatusChangesClone;
};
