/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import { createCustomerObject, overWriteAssetViewSettings } from './util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
import {
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone,
	selectHasChanges,
	selectIsOutOfSync,
	selectCustomers,
	selectErrorMessageSaveEditedCustomer
} from '../selectors';

import {
	setSelectedCustomer,
	setSelectedCustomerChangesClone,
	setIsSavingEditedCustomer,
	setIsDeletingCustomer,
	setErrorMessageSaveEditedCustomer,
	setConfirmationMessageKey,
	setShowConfirmation
} from './actions';

import { thunkLoad as thunkLoadCogCustomers } from '../../Sync/CogCustomers/actions';
import { thunkLoad as thunkLoadDdbCustomers } from '../../Sync/DdbCustomers/actions';
import { thunkLoad as thunkLoadDdbCustomersEntities } from '../../Sync/DdbCustomersEntities/actions';
import { thunkLoad as thunkLoadDdbCustomersSettings } from '../../Sync/DdbCustomersSettings/actions';
import { thunkLoad as thunkLoadPsqlCustomers } from '../../Sync/PsqlCustomers/actions';
import { thunkLoad as thunkLoadPsqlCustomersEntities } from '../../Sync/PsqlCustomersEntities/actions';
import { thunkLoad as thunkLoadPsqlCustomersApplications } from '../../Sync/PsqlCustomersApplications/actions';
import { thunkLoad as thunkLoadPsqlCustomersAreas } from '../../Sync/PsqlCustomerAreas/actions';
import { thunkLoad as thunkLoadPsqlCustomersRegulatoryStatus } from '../../Sync/PsqlCustomerRegulatoryStatus/actions';
import { thunkLoad as thunkLoadPsqlCustomersPipes } from '../../Sync/PsqlCustomerPipes/actions';
import { thunkLoad as thunkLoadAvEfsCustomersApplications } from '../../Sync/AvEfsCustomers/actions';
import { selectMatchTerm as selectAvEfsMatchTerm } from '../../Sync/AvEfsCustomers/selectors';
import {
	saveCustomerV2,
	deleteCustomerV2,
	DDBGET
} from '../../../../api/customers';
import ConfirmUndoQueue from '../ConfirmUndoQueue';
import { MESSAGE_KEY_DELETE } from '../ConfirmUndoQueue.constants';
// import { cleanName } from '../../../../api/util/customer';

const isDefined = v => v !== undefined && v !== null;

const loadCustomers = () => dispatch => {
	dispatch(thunkLoadCogCustomers());

	dispatch(thunkLoadDdbCustomers());
	dispatch(thunkLoadDdbCustomersEntities());
	dispatch(thunkLoadDdbCustomersSettings());

	dispatch(thunkLoadPsqlCustomers());
	dispatch(thunkLoadPsqlCustomersEntities());
	dispatch(thunkLoadPsqlCustomersApplications());
	dispatch(thunkLoadPsqlCustomersAreas());
  dispatch(thunkLoadPsqlCustomersRegulatoryStatus());
  dispatch(thunkLoadPsqlCustomersPipes());

	dispatch(thunkLoadAvEfsCustomersApplications());
};

const thunkSetErrorMessageSaveEditedCustomer = message => (
	dispatch,
	getState
) => {
	const currentMessage = selectErrorMessageSaveEditedCustomer(getState().Admin);
	if (currentMessage !== message) {
		dispatch(setErrorMessageSaveEditedCustomer(message));
	}
};

const thunkSetSelectedCustomer = (customerName, isNewCustomer) => (
	dispatch,
	getState
) => {
	if (isDefined(customerName)) {
		dispatch(thunkSetErrorMessageSaveEditedCustomer(undefined));
		const { Admin: stateAdmin } = getState();
		const customerObject = createCustomerObject(stateAdmin, customerName);
		if (isNewCustomer) {
			customerObject.isNewCustomer = true;
		}
		const defaultMatchTerm = selectAvEfsMatchTerm(stateAdmin, {
			customer: customerName
		});
		const clone = _.cloneDeep(customerObject);
		overWriteAssetViewSettings(clone, defaultMatchTerm);
		dispatch(setSelectedCustomer(customerObject));
		dispatch(setSelectedCustomerChangesClone(clone));
	} else {
		dispatch(setSelectedCustomer(undefined));
		dispatch(setSelectedCustomerChangesClone(undefined));
	}
};

const thunkSetNewSelectedCustomer = () => dispatch => {
	dispatch(thunkSetSelectedCustomer('', true));
};

const computeErrMessageCustomer = (customer, newCustomer, allCustomers) => {
	const { isNewCustomer } = newCustomer;

	const onlyNameCaseChange =
		customer.name !== newCustomer.name &&
		(customer.name || '').toLowerCase() ===
			(newCustomer.name || '').toLowerCase();

	let errCustomerExists = false;
	let errCustomerNameCaseChange = false;
	if (isNewCustomer) {
		errCustomerExists =
			allCustomers
				.map(c => c.toLowerCase())
				.indexOf(newCustomer.name.toLowerCase()) > -1;
	} else if (onlyNameCaseChange) {
		errCustomerNameCaseChange = true;
	} else {
		errCustomerExists =
			customer.name !== newCustomer.name &&
			allCustomers
				.map(c => c.toLowerCase())
				.indexOf(newCustomer.name.toLowerCase()) > -1;
	}

	const errCustomerNameRequired =
		!newCustomer.name || !newCustomer.name.trim().length;

	if (errCustomerNameCaseChange) {
		return `
Name case change error: Changing just the capitalization of a customer name with no other changes must be done in 2 steps\n
- Change the customer name by adding an arbitrary character like "x" and hit save.\n
- Reselect the customer name, remove "x", fix letter casing, and hit save.
      `;
	}

	if (errCustomerExists) {
		return 'A customer with this name already exists';
	}

	if (errCustomerNameRequired) {
		return 'Customer name is required';
	}

	return undefined;
};

const thunkSaveCustomer = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const allCustomers = selectCustomers(stateAdmin);
	const customer = selectSelectedCustomer(stateAdmin);
	const newCustomer = selectSelectedCustomerChangesClone(stateAdmin);
	const hasChanges = selectHasChanges(stateAdmin);
	const isOutOfSync = selectIsOutOfSync(stateAdmin, {
		customer: customer.name
	});
	const errMessageCustomer = computeErrMessageCustomer(
		customer,
		newCustomer,
		allCustomers
	);

	if (errMessageCustomer) {
		dispatch(thunkSetErrorMessageSaveEditedCustomer(errMessageCustomer));
	} else if ((isOutOfSync || hasChanges) && newCustomer && newCustomer.name) {
		const customerPayload = _.cloneDeep(newCustomer);

		// HANDLE ASSETVIEW SETTINGS CORRECTIONS
		const defaultMatchTerm = selectAvEfsMatchTerm(stateAdmin, {
			customer: customer.name
		});
		overWriteAssetViewSettings(customerPayload, defaultMatchTerm, {
			ignoreEnabled: true
		});

		// HANDLE NAME CHANGES
		const didNameChange =
			customer?.name && newCustomer?.name && customer.name !== newCustomer.name;
		if (didNameChange) {
			customerPayload.name = customer.name;
			customerPayload.newName = newCustomer.name;
		}

		// HANDLE DOMAIN CHNAnGES
		const didDomainChange =
			customer?.domain &&
			newCustomer?.domain &&
			customer.domain !== newCustomer.domain;
		if (didDomainChange) {
			customerPayload.domain = customer.domain;
			customerPayload.newDomain = newCustomer.domain;
		}
		dispatch(thunkSetErrorMessageSaveEditedCustomer(undefined));
		dispatch(setIsSavingEditedCustomer(true));

		saveCustomerV2({ customer: customerPayload })
			.then(() => {
				dispatch(setSelectedCustomer(undefined));
				dispatch(setSelectedCustomerChangesClone(undefined));
				dispatch(setIsSavingEditedCustomer(false));
				dispatch(loadCustomers());
			})
			.catch(() => {
				dispatch(
					thunkSetErrorMessageSaveEditedCustomer(
						'An error occurred when trying to save the new customer'
					)
				);
				dispatch(setIsSavingEditedCustomer(false));
			});
	}
};

const thunkDeleteCustomer = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const customer = selectSelectedCustomer(stateAdmin);
	if (customer && customer.name) {
		ConfirmUndoQueue.clear();
		dispatch(setConfirmationMessageKey(MESSAGE_KEY_DELETE));
		dispatch(setShowConfirmation(true));

		ConfirmUndoQueue.addToConfirmQueue(() => {
			dispatch(thunkSetErrorMessageSaveEditedCustomer(undefined));
			dispatch(setIsDeletingCustomer(true));

			deleteCustomerV2({ customer })
				.then(() => {
					dispatch(setSelectedCustomer(undefined));
					dispatch(setSelectedCustomerChangesClone(undefined));
					dispatch(setConfirmationMessageKey());
					dispatch(setShowConfirmation(false));
					dispatch(setIsDeletingCustomer(false));
					dispatch(loadCustomers());
				})
				.catch(() => {
					dispatch(
						thunkSetErrorMessageSaveEditedCustomer(
							'An error occurred when trying to save the new customer'
						)
					);
					dispatch(setConfirmationMessageKey());
					dispatch(setShowConfirmation(false));
					dispatch(setIsDeletingCustomer(false));
				});
		});
		ConfirmUndoQueue.addToUndoQueue(() => {
			dispatch(setConfirmationMessageKey(undefined));
			dispatch(setShowConfirmation(false));
		});
	}
};

const thunkConfirmDeleteCustomer = () => () => {
	ConfirmUndoQueue.executeConfirmQueue();
};
const thunkCancelDeleteCustomer = () => () => {
	ConfirmUndoQueue.executeUndoQueue();
};

const thunkDDBGET = () => (dispatch, getState) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const customer = selectSelectedCustomer(stateAdmin);
	if (customer && customer.name) {
		DDBGET({ customer })
			.then(r => {
				console.log(r);
			})
			.catch(e => {
				console.error(e);
			});
	}
};

export {
	loadCustomers,
	thunkSetSelectedCustomer,
	thunkSetNewSelectedCustomer,
	thunkSetErrorMessageSaveEditedCustomer,
	thunkSaveCustomer,
	thunkDeleteCustomer,
	thunkConfirmDeleteCustomer,
	thunkCancelDeleteCustomer,
	thunkDDBGET
};
