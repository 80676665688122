/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	theCloneRegulatoryStatusHasChanges,
	createRegulatoryStatusObject,
	regulatoryStatusIsEmpty,
	regulatoryStatusIsNew,
	regulatoryStatusRemoveEmptyStringProps,
	regulatoryStatusTrimPropWrappingWhiteSpace
} from '../util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
// import {
// 	selectSelectedRegulatoryStatus,
// 	selectSelectedRegulatoryStatusChangesClone
// } from '../selectors';
import {
	setSelectedRegulatoryStatus,
	setSelectedRegulatoryStatusChangesClone
} from './actions';

import {
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone
} from '../../CustomersV2/selectors';
import { setSelectedCustomerChangesClone } from '../../CustomersV2/actions';



/** **********
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const createCustomerClone = customerObj => {
	return {
		...customerObj,
		regulatorystatuses: customerObj.regulatorystatuses
			? [...customerObj.regulatorystatuses]
			: []
	};
};

const computeHasNameConflict = (rs, regulatorystatuses) => {
  return !rs.isDeleted && (regulatorystatuses || [])
    .filter(a => a.clientId !== rs.clientId)
    .some(a => (
      !a.isDeleted && (
        (a.name || '').trim().toUpperCase() === (rs.name || '').trim().toUpperCase()
      )
    ));
}



/** **********
 * STATE - various clones ( customers & regulatory statuses )
 */

const selectProps = (getState, regulatoryStatus) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const origCustomer = selectSelectedCustomer(stateAdmin);
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const newCustomerClone = createCustomerClone(customerChangesClone);

	const origData = {
		customer: origCustomer,
		regulatorystatuses: origCustomer.regulatorystatuses,
    idx: -1,
    regulatoryStatus: undefined
	};

	const chngData = {
		customer: customerChangesClone,
		regulatorystatuses: customerChangesClone.regulatorystatuses,
    idx: -1,
    regulatoryStatus: undefined

	};

	const newData = {
		customer: newCustomerClone,
		regulatorystatuses: newCustomerClone.regulatorystatuses,
    idx: -1,
    regulatoryStatus: undefined
	};

	if (isDefined(regulatoryStatus)) {
		origData.idx = (origData.regulatorystatuses || []).findIndex(
			o => o.clientId === regulatoryStatus.clientId
		);
		if (origData.idx > -1) {
			origData.regulatoryStatus = origData.regulatorystatuses[origData.idx];
		}
		chngData.idx = (chngData.regulatorystatuses || []).findIndex(
			o => o.clientId === regulatoryStatus.clientId
		);
		if (chngData.idx > -1) {
			chngData.regulatoryStatus = chngData.regulatorystatuses[chngData.idx];
		}
		newData.idx = (newData.regulatorystatuses || []).findIndex(
			o => o.clientId === regulatoryStatus.clientId
		);
		if (newData.idx > -1) {
			newData.regulatorystatuses[newData.idx] = {
				...newData.regulatorystatuses[newData.idx]
			};
			newData.regulatoryStatus = newData.regulatorystatuses[newData.idx];
		}
		// debugger;
	} else {
		// debugger;
	}

	return {
		origData,
		chngData,
		newData
	};
};



/** **********
 * THUNKS
 */

const thunkSetSelectedRegulatoryStatus = regulatoryStatus => dispatch => {
	if (isDefined(regulatoryStatus)) {
		const clone = { ...regulatoryStatus };
		dispatch(setSelectedRegulatoryStatus(regulatoryStatus));
		dispatch(setSelectedRegulatoryStatusChangesClone(clone));
	} else {
		dispatch(setSelectedRegulatoryStatus(undefined));
		dispatch(setSelectedRegulatoryStatusChangesClone(undefined));
	}
};

const thunkSetNewSelectedRegulatoryStatus = () => (dispatch, getState) => {
	const { newData } = selectProps(getState);
	const newRegulatoryStatus = createRegulatoryStatusObject({
		customerId: newData.customer.id
	});
	newData.customer.regulatorystatuses.push(newRegulatoryStatus);
	dispatch(setSelectedCustomerChangesClone(newData.customer));
	dispatch(thunkSetSelectedRegulatoryStatus(newRegulatoryStatus));
};

const thunkUpdateRegulatoryStatusProp = (
	regulatoryStatus,
	prop,
	value
) => (dispatch, getState) => {
	const { chngData } = selectProps(getState);
	const isPropChanged = regulatoryStatus && regulatoryStatus[prop] !== value;

	if (isPropChanged) {
		const clone = { ...regulatoryStatus };
		clone[prop] = value;
		clone.hasChanges = true;
    clone.hasKeyConflict = computeHasNameConflict(clone, chngData.regulatorystatuses);
		dispatch(setSelectedRegulatoryStatusChangesClone(clone));
	}
};

const thunkDeleteRegulatoryStatus = regulatoryStatus => (
	dispatch,
	getState
) => {
	const { newData } = selectProps(getState, regulatoryStatus);
	if (newData.regulatoryStatus && !newData.regulatoryStatus.isDeleted) {
		if (regulatoryStatusIsNew(newData.regulatoryStatus)) {
			newData.regulatorystatuses.splice(newData.idx, 1);
		} else {
			newData.regulatoryStatus.isDeleted = true;
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
	}
};

const thunkCancelRegulatoryStatusEditor = regulatoryStatus => (
	dispatch,
	getState
) => {
	const { newData } = selectProps(getState, regulatoryStatus);
	const isNewAndEmpty =
		newData.regulatoryStatus &&
		regulatoryStatusIsNew(newData.regulatoryStatus) &&
		regulatoryStatusIsEmpty(newData.regulatoryStatus);
	if (isNewAndEmpty) {
		dispatch(thunkDeleteRegulatoryStatus(regulatoryStatus));
	}
	dispatch(thunkSetSelectedRegulatoryStatus(undefined));
};

const thunkDoneEditingRegulatoryStatus = regulatoryStatus => (
	dispatch,
	getState
) => {
	if (regulatoryStatus && !regulatoryStatus.hasKeyConflict) {
		const { newData } = selectProps(getState, regulatoryStatus);
		if (newData.idx > -1) {
			const newRegulatoryStatusIsEmpty =
				regulatoryStatusIsNew(regulatoryStatus) &&
				regulatoryStatusIsEmpty(regulatoryStatus);
			const existingRegulatoryStatusIsEmpty =
				!regulatoryStatusIsNew(regulatoryStatus) &&
				regulatoryStatusIsEmpty(regulatoryStatus);
			const existingRegulatoryStatusNoChanges =
				!regulatoryStatusIsNew(regulatoryStatus) &&
				!existingRegulatoryStatusIsEmpty &&
				!theCloneRegulatoryStatusHasChanges(
					newData.regulatoryStatus,
					regulatoryStatus
				);

			// UPDATE STATE
			if (newRegulatoryStatusIsEmpty) {
				dispatch(thunkDeleteRegulatoryStatus(regulatoryStatus));
				dispatch(thunkSetSelectedRegulatoryStatus(undefined));
			} else if (existingRegulatoryStatusNoChanges) {
				dispatch(thunkSetSelectedRegulatoryStatus(undefined));
			} else if (existingRegulatoryStatusIsEmpty) {
				// do nothing, should be caught in form validation and never make it to this line of code.
			} else {
				const sanitizedRegulatoryStatus = regulatoryStatusTrimPropWrappingWhiteSpace(
					regulatoryStatusRemoveEmptyStringProps(regulatoryStatus)
				);
				newData.regulatoryStatus = sanitizedRegulatoryStatus;
				newData.regulatorystatuses[newData.idx] = newData.regulatoryStatus;
				dispatch(setSelectedCustomerChangesClone(newData.customer));
				dispatch(thunkSetSelectedRegulatoryStatus(undefined));
			}
		}
	}
};

const thunkRevertRegulatoryStatusChanges = regulatoryStatus => (
	dispatch,
	getState
) => {
	const { origData, newData } = selectProps(getState, regulatoryStatus);

	if (newData.idx > -1) {
    if (origData.idx > -1) {
      const clone = { ...origData.regulatorystatuses[origData.idx] };
      newData.regulatorystatuses[newData.idx] = clone;
      clone.hasKeyConflict = computeHasNameConflict(clone, newData.regulatorystatuses);      
		} else {
			newData.regulatorystatuses.splice(newData.idx, 1);
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
		dispatch(thunkSetSelectedRegulatoryStatus(undefined));
	}
};

export {
	thunkSetSelectedRegulatoryStatus,
	thunkSetNewSelectedRegulatoryStatus,
	thunkDoneEditingRegulatoryStatus,
	thunkUpdateRegulatoryStatusProp,
	thunkDeleteRegulatoryStatus,
	thunkCancelRegulatoryStatusEditor,
	thunkRevertRegulatoryStatusChanges
};
