/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	theCloneAreaHasChanges,
	createAreaObject,
	areaIsEmpty,
	areaIsNew,
	areaRemoveEmptyStringProps,
	areaTrimPropWrappingWhiteSpace
} from '../util';

import { selectRootSliceAdmin } from '../../../Main/selectors';
import { setSelectedArea, setSelectedAreaChangesClone } from './actions';

import {
	selectSelectedCustomer,
	selectSelectedCustomerChangesClone
} from '../../CustomersV2/selectors';
import { setSelectedCustomerChangesClone } from '../../CustomersV2/actions';



/** **********
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const createCustomerClone = customerObj => {
	return {
		...customerObj,
		areas: customerObj.areas ? [...customerObj.areas] : []
	};
};

const computeHasNameConflict = (area, areas) => {  
  const result = !area.isDeleted && (areas || [])
    .filter(a => a.clientId !== area.clientId)
    .some(a => (
      !a.isDeleted && (
        (a.name || '').trim().toUpperCase() === (area.name || '').trim().toUpperCase()
      )
    ));
  return result;
}



/** **********
 * STATE - various clones ( customers & areas )
 */

const selectProps = (getState, area) => {
	const stateAdmin = selectRootSliceAdmin(getState());
	const origCustomer = selectSelectedCustomer(stateAdmin);
	const customerChangesClone = selectSelectedCustomerChangesClone(stateAdmin);
	const newCustomerClone = createCustomerClone(customerChangesClone);

	const origData = {
		customer: origCustomer,
    areas: origCustomer.areas,
    idx: -1,
    area: undefined
	};

	const chngData = {
		customer: customerChangesClone,
		areas: customerChangesClone.areas,
    idx: -1,
    area: undefined
	};

	const newData = {
		customer: newCustomerClone,
    areas: newCustomerClone.areas,
    idx: -1,
    area: undefined
	};

	if (isDefined(area)) {
		origData.idx = (origData.areas || []).findIndex(
			o => o.clientId === area.clientId
		);
		if (origData.idx > -1) {
			origData.area = origData.areas[origData.idx];
		}
		chngData.idx = (chngData.areas || []).findIndex(
			o => o.clientId === area.clientId
		);
		if (chngData.idx > -1) {
			chngData.area = chngData.areas[chngData.idx];
		}
		newData.idx = (newData.areas || []).findIndex(
			o => o.clientId === area.clientId
		);
		if (newData.idx > -1) {
			newData.areas[newData.idx] = { ...newData.areas[newData.idx] };
			newData.area = newData.areas[newData.idx];
		}
		// debugger;
	} else {
		// debugger;
	}

	return {
		origData,
		chngData,
		newData
	};
};



/** **********
 * THUNKS
 */

const thunkSetSelectedArea = area => dispatch => {
	if (isDefined(area)) {
		const clone = { ...area };
		dispatch(setSelectedArea(area));
		dispatch(setSelectedAreaChangesClone(clone));
	} else {
		dispatch(setSelectedArea(undefined));
		dispatch(setSelectedAreaChangesClone(undefined));
	}
};

const thunkSetNewSelectedArea = () => (dispatch, getState) => {
	const { newData } = selectProps(getState);
	const newArea = createAreaObject({
		customerId: newData.customer.id
	});
	newData.customer.areas.push(newArea);
	dispatch(setSelectedCustomerChangesClone(newData.customer));
	dispatch(thunkSetSelectedArea(newArea));
};

const thunkUpdateAreaProp = (area, prop, value) => (dispatch, getState) => {
	const { chngData } = selectProps(getState);
	const isPropChanged = area && area[prop] !== value;

	if (isPropChanged) {
		const clone = { ...area };
		clone[prop] = value;
    clone.hasChanges = true;
    clone.hasKeyConflict = computeHasNameConflict(clone, chngData.areas);
		dispatch(setSelectedAreaChangesClone(clone));
	}
};

const thunkDeleteArea = area => (dispatch, getState) => {
	const { newData } = selectProps(getState, area);
	if (newData.area && !newData.area.isDeleted) {
		if (areaIsNew(newData.area)) {
			newData.areas.splice(newData.idx, 1);
		} else {
			newData.area.isDeleted = true;
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
	}
};

const thunkCancelAreaEditor = area => (dispatch, getState) => {
	const { newData } = selectProps(getState, area);
	const isNewAndEmpty =
		newData.area && areaIsNew(newData.area) && areaIsEmpty(newData.area);
	if (isNewAndEmpty) {
		dispatch(thunkDeleteArea(area));
	}
	dispatch(thunkSetSelectedArea(undefined));
};

const thunkDoneEditingArea = area => (dispatch, getState) => {
	if (area && !area.hasKeyConflict) {
		const { newData } = selectProps(getState, area);
		if (newData.idx > -1) {
			const newAreaIsEmpty = areaIsNew(area) && areaIsEmpty(area);
			const existingAreaIsEmpty = !areaIsNew(area) && areaIsEmpty(area);
			const existingAreaNoChanges =
				!areaIsNew(area) &&
				!existingAreaIsEmpty &&
				!theCloneAreaHasChanges(newData.area, area);

			// UPDATE STATE
			if (newAreaIsEmpty) {
				dispatch(thunkDeleteArea(area));
				dispatch(thunkSetSelectedArea(undefined));
			} else if (existingAreaNoChanges) {
				dispatch(thunkSetSelectedArea(undefined));
			} else if (existingAreaIsEmpty) {
				// do nothing, should be caught in form validation and never make it to this line of code.
			} else {
				const sanitizedArea = areaTrimPropWrappingWhiteSpace(
					areaRemoveEmptyStringProps(area)
				);
				newData.area = sanitizedArea;
				newData.areas[newData.idx] = newData.area;
				dispatch(setSelectedCustomerChangesClone(newData.customer));
				dispatch(thunkSetSelectedArea(undefined));
			}
		}
	}
};

const thunkRevertAreaChanges = area => (dispatch, getState) => {
	const { origData, newData } = selectProps(getState, area);

	if (newData.idx > -1) {
    if (origData.idx > -1) {
      const clone = { ...origData.areas[origData.idx] }
      newData.areas[newData.idx] = clone;
      clone.hasKeyConflict = computeHasNameConflict(clone, newData.areas);      
		} else {
			newData.areas.splice(newData.idx, 1);
		}
		dispatch(setSelectedCustomerChangesClone(newData.customer));
		dispatch(thunkSetSelectedArea(undefined));
	}
};

export {
	thunkSetSelectedArea,
	thunkSetNewSelectedArea,
	thunkDoneEditingArea,
	thunkUpdateAreaProp,
	thunkDeleteArea,
	thunkCancelAreaEditor,
	thunkRevertAreaChanges
};
