/* eslint-disable import/no-named-as-default-member */
import { createSortText } from 'aegion_common_utilities/lib/Sort';
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';
import DDB_ENTITIES from '../Sync/DdbCustomersEntities/selectors';
import PSQL_ENTITIES from '../Sync/PsqlCustomersEntities/selectors';

export const createEntityObject = ({
	name = '',
	active = true,
	isNew
} = {}) => {
	const result = {
		clientId: goodEnoughUUID(),
		name,
		active,
		newName: undefined
	};
	if (isNew) {
		result.isNew = true;
	}
	return result;
};

export const sortEntities = createSortText('ASC', {
	caseSensitive: false
});

export const createEntitiesArray = (stateAdmin, customerName) => {
	const ddbEntitiesMap = DDB_ENTITIES.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});
	const psqlEntitiesMap = PSQL_ENTITIES.selectBoolMapByCustomer(stateAdmin, {
		customer: customerName
	});

	const entitiesMap = { ...ddbEntitiesMap, ...psqlEntitiesMap };
	const keys = Object.keys(entitiesMap).sort(sortEntities);
	return keys.reduce((acc, k) => {
		acc.push(
			createEntityObject({
				name: k,
				active: !!entitiesMap[k]
			})
		);
		return acc;
	}, []);
};

export default { createEntityObject, sortEntities, createEntitiesArray };
