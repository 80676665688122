import { createSortNumeric } from 'aegion_common_utilities/src/Sort/sort';
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';
import { selectDataByCustomer } from '../Sync/PsqlCustomerRegulatoryStatus/selectors';

const isDefined = v => v !== null && v !== undefined;

const isEmptyString = v => typeof v === 'string' && v.trim().length === 0;

const CORE_PROPS = ['id', 'customerId', 'name', 'hasValues'];

const createRegulatoryStatusObject = ({
	id,
	customerId,
	name,
	hasValues
} = {}) => {
	const result = {
		clientId: goodEnoughUUID(),
		id,
		customerId,
		name,
		hasValues
	};

	return result;
};

const sortRegulatoryStatuses = createSortNumeric('ASC', {
	getValue: i => i.name,
	caseSensitive: false,
	invalidLast: true
});

const createRegulatoryStatusesArray = (stateAdmin, customerName) => {
	const psqlRegulatoryStatuses = selectDataByCustomer(stateAdmin, {
		customer: customerName
	});

	return psqlRegulatoryStatuses
		.map(createRegulatoryStatusObject)
		.sort(sortRegulatoryStatuses);
};

const regulatoryStatusHasChanges = regulatoryStatus =>
	!regulatoryStatus.id || !!regulatoryStatus.hasChanges;

const regulatoryStatusIsNew = regulatoryStatus => !regulatoryStatus?.id;

const regulatoryStatusIsEmpty = regulatoryStatus => {
	if (!regulatoryStatus) {
		return true;
	}

	const CHECK_PROPS = ['name'];
	const hasDefinedProp = CHECK_PROPS.some(
		p => isDefined(regulatoryStatus[p]) && !isEmptyString(regulatoryStatus[p])
	);
	return !hasDefinedProp;
};

const regulatoryStatusRemoveEmptyStringProps = regulatoryStatus => {
	if (!regulatoryStatus) {
		return regulatoryStatus;
	}

	let isModified = false;
	const clone = { ...regulatoryStatus };
	CORE_PROPS.forEach(p => {
		if (isEmptyString(clone[p])) {
			clone[p] = undefined;
			isModified = true;
		}
	});

	return isModified ? clone : regulatoryStatus;
};

const regulatoryStatusTrimPropWrappingWhiteSpace = regulatoryStatus => {
	if (!regulatoryStatus) {
		return regulatoryStatus;
	}

	let isModified = false;
	const clone = { ...regulatoryStatus };
	CORE_PROPS.forEach(p => {
		if (
			typeof clone[p] === 'string' &&
			clone[p].trim().length !== clone[p].length
		) {
			clone[p] = clone[p].trim();
			isModified = true;
		}
	});

	return isModified ? clone : regulatoryStatus;
};

const theCloneRegulatoryStatusHasChanges = (orig = {}, clone = {}) => {
	const res = CORE_PROPS.some(p => clone[p] !== orig[p]);
	return res;
};

export {
	regulatoryStatusIsNew,
	regulatoryStatusIsEmpty,
	regulatoryStatusRemoveEmptyStringProps,
	regulatoryStatusTrimPropWrappingWhiteSpace,
	createRegulatoryStatusObject,
	createRegulatoryStatusesArray,
	regulatoryStatusHasChanges,
	theCloneRegulatoryStatusHasChanges
};
