/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { set, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { initialState } from './initialState';
import {
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
  [clear]: () => {
		return _.cloneDeep(initialState);
	},

	[set]: (state, { payload: { data } }) => {
		return {
			...state,
      data
		};
	},

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
      isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
      isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
    };
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
      errorMessage: newErrorMessage
		};
	}
};

export default handleActions(reducerParts, initialState);
