/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { set, setCustomer, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectThisSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

	[set]: (state, { payload: { items } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        items,
        itemsMap: (items || []).reduce((acc, item) => { 
          acc[item?.id] = item;
          return acc;
        }, {})
			}
		};
  },
  
  [setCustomer]: (state, { payload: { customer } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        customer
			}
		};
  },

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectThisSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
	}
};

export default reducerParts;
