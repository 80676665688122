import { createSortNumeric } from 'aegion_common_utilities/src/Sort/sort';
import { goodEnoughUUID } from '../../../../scanline/utils/uuid';
import { selectDataByCustomer } from '../Sync/PsqlCustomerPipes/selectors';

const isDefined = v => v !== null && v !== undefined;

const isEmptyString = v => typeof v === 'string' && v.trim().length === 0;

const CORE_PROPS = [
	'id',
	'customerId',
	'entityId',
	'entityClientId',
	'areaId',
	'areaClientId',
	'name',
	'hasValues'
];

const createPipeObject = (pipe = {}, entities, areas) => {
	const entity = pipe.entityId ? entities.find(e => pipe.entityName && e.name === pipe.entityName) : undefined;
	const area = pipe.areaId ? areas.find(e => pipe.areaName && e.name === pipe.areaName) : undefined;
	const result = {
    ...pipe,
    clientId: goodEnoughUUID(),
		entityClientId: entity?.clientId,
		areaClientId: area?.clientId,
  };

	return result;
};

const sortPipes = createSortNumeric('ASC', {
	getValue: i => i.name,
	caseSensitive: false,
	invalidLast: true
});

const createPipesArray = (stateAdmin, customerName, entities, areas) => {
	const psqlPipes = selectDataByCustomer(stateAdmin, {
		customer: customerName
	});

	const _createPipeObject = pipe => createPipeObject(pipe, entities, areas);
	return psqlPipes.map(_createPipeObject).sort(sortPipes);
};

const pipeHasChanges = pipe => !pipe.id || !!pipe.hasChanges;

const pipeIsNew = pipe => !pipe?.id;

const pipeIsEmpty = pipe => {
	if (!pipe) {
		return true;
	}

	const CHECK_PROPS = ['name', 'entityId', 'areaId'];
	const hasDefinedProp = CHECK_PROPS.some(
		p => isDefined(pipe[p]) && !isEmptyString(pipe[p])
	);
	return !hasDefinedProp;
};

const pipeRemoveEmptyStringProps = pipe => {
	if (!pipe) {
		return pipe;
	}

	let isModified = false;
	const clone = { ...pipe };
	CORE_PROPS.forEach(p => {
		if (isEmptyString(clone[p])) {
			clone[p] = undefined;
			isModified = true;
		}
	});

	return isModified ? clone : pipe;
};

const pipeTrimPropWrappingWhiteSpace = pipe => {
	if (!pipe) {
		return pipe;
	}

	let isModified = false;
	const clone = { ...pipe };
	CORE_PROPS.forEach(p => {
		if (
			typeof clone[p] === 'string' &&
			clone[p].trim().length !== clone[p].length
		) {
			clone[p] = clone[p].trim();
			isModified = true;
		}
	});

	return isModified ? clone : pipe;
};

const theClonePipeHasChanges = (orig = {}, clone = {}) => {
	const res = CORE_PROPS.some(p => clone[p] !== orig[p]);
	return res;
};

export {
	pipeIsNew,
	pipeIsEmpty,
	pipeRemoveEmptyStringProps,
	pipeTrimPropWrappingWhiteSpace,
	createPipeObject,
	createPipesArray,
	pipeHasChanges,
	theClonePipeHasChanges
};
