/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE } from '../constants';

export const selectRootSliceLPR = state => { 
  if (state[ROOT_SLICE]) { 
    return state[ROOT_SLICE];
  }

  // else assume state is already root.
  return state;
};
