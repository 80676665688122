import { selectSliceCisview } from '../../selectors/HELPERS';
import { ROOT_SLICE } from './constants';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const isCisviewState = state => {
	const keyMap = Object.keys(state).reduce((acc, k) => {
		acc[k] = true;
		return acc;
	}, {});

	const checkForKeys = [ROOT_SLICE];
	return checkForKeys.every(k => keyMap[k]);
};

export const selectRootSlice = state => { 
  const sliceState = selectSliceCisview(state);
  if (isCisviewState(sliceState)) { 
    return sliceState[ROOT_SLICE];
  }

  // assume local slice state;
  return sliceState;
};

export const selectData = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { data } = slice;
	return data;
};

export const selectDataByCustomer = (stateAdmin, { customer }) => {
	const data = selectData(stateAdmin);
	return data[customer] || EMPTY_ARRAY;
};

export const selectBoolMap = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { boolMap } = slice;
	return boolMap;
};

export const selectBoolMapByCustomer = (stateAdmin, { customer } = {}) => {
	const slice = selectRootSlice(stateAdmin);
	const { boolMap } = slice;
	return boolMap[customer] || EMPTY_OBJECT;
};

export const selectIsLoading = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = stateAdmin => {
	const slice = selectRootSlice(stateAdmin);
	const { errorMessage } = slice;
	return errorMessage;
};

export default {
	selectData,
	selectDataByCustomer,
	selectBoolMap,
	selectBoolMapByCustomer,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
