/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// ADMIN CUSTOMERS - PSQL CUSTOMERS AREAS
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS } from './slices/LinesAggs/constants';
import { initialState as LinesAggs } from './slices/LinesAggs/initialState';
import LinesAggsReducerParts from './slices/LinesAggs/reducerParts';


const initialState = {
	[ROOT_SLICE_LINES_AGGS]: {
		...LinesAggs
	}
};

const reducerParts = {
	...LinesAggsReducerParts,
};

export { ROOT_SLICE } from './constants'
export default handleActions(reducerParts, initialState);
